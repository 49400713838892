<template>
  <section>
    <div class="m-1 mr-4 px-0 flex w-full justify-between">
      <div class="w-full">
        <breadcrumb
          :title="proposta.TypeName"
          :actualPage="
            proposta.ProposalTypeId == 2
              ? 'Acompanhamento do endosso'
              : 'Acompanhamento da proposta'
          "
          previousPage="propostas"
          previousPageTitle="Lista de propostas e cotações"
        />
      </div>

      <div
        class="w-full mt-5"
        v-if="podeCancelarProposta || podeRealizarEmissao"
      >
        <vs-col vs-type="flex" vs-justify="end" vs-align="end">
          <vs-button
            class="button-default"
            v-if="podeCancelarProposta"
            @click="cancelarProposta(proposta.UniqueId)"
            type="filled"
          >
            {{
              proposta.ProposalTypeId == 1
                ? "Cancelar proposta"
                : "Cancelar endosso"
            }}
          </vs-button>

          <vs-button
            class="button-primary ml-5"
            v-if="podeRealizarEmissao"
            @click="
              proposta.ProposalTypeId == 1
                ? seguirComEmissao()
                : seguirComEndosso()
            "
            type="filled"
          >
            <p v-if="proposta.ProposalTypeId == 1">Seguir com emissão</p>

             <p v-else-if="proposta.Endorsement">
              {{
                proposta.Endorsement.EndorsementReasonId == 18
                  ? "Escolher parcelamento"
                  : "Emitir endosso"
              }}
              </p>
          </vs-button>
        </vs-col>
      </div>
    </div>

    <vs-card class="mt-4 p-8 dados-proposta" v-if="proposta.Number">
      <h3 class="text-primary mb-3">
        Número
        {{
          proposta.ProposalTypeId == 1
            ? "da proposta:"
            : "da proposta de endosso:"
        }}
        <b>{{ proposta.Number }}</b>
      </h3>

      <p class="mb-5 font-semibold">
        Data {{ proposta.ProposalTypeId == 1 ? "da proposta:" : "do endosso:" }}
        {{ new Date(proposta.CreateDate) | dateFormat("DD/MM/YYYY") }}
      </p>

      <TagCanalCorretorMapfre
          v-if="podeExibirTagCanalCorretorMapfre"
          class="mb-6"
          :tipoCanalCorretora="tipoCanalCorretora"
          view="timeline"
        />

      <div class="flex mt-2 mb-10">
        <vs-button
          v-if="podeGerarMinuta()"
          @click="gerarMinutaClick(proposta.UniqueId)"
          color="primary"
        >
          <span class="font-semibold pr-2 pl-2">
            Download da minuta
          </span>
        </vs-button>

        <vs-button
          v-if="verificaSeBoletoEmitido()"
          @click="parcelasModal = true"
          color="primary"
        >
          <span class="font-semibold pr-2 pl-2">
            Download do(s) boleto(s)
          </span>
        </vs-button>
        <vs-button
          v-if="verificaSeStatusEhEmitida()"
          class="ml-5"
          @click="downloadApoliceClick(proposta.UniqueId)"
          color="primary"
        >
          <span class="font-semibold pr-2 pl-2">
            {{
              proposta.ProposalTypeId == 2
                ? "Download do endosso "
                : " Download da apólice "
            }}
          </span>
        </vs-button>
      </div>
      <div class="vx-row px-4 relative">
        <div class="vx-col w-full container-timeline p-5">
          <div class="vx-row px-4 relative">
            <div class="vx-col w-full p-5">
              <div class="macro-timeline">
                <ul class="status" v-if="(proposta || {}).Timeline">
                  <li
                    v-for="(status, index) in [
                      'Cotação',
                      'Proposta',
                      'Emissão'
                    ]"
                    :key="index"
                  >
                    <span
                      class="material-icons"
                      :class="getTimelineColor(index + 1)"
                    >
                      {{ getTimelineIcon(index + 1) }}
                    </span>
                    <p>
                      <b>{{
                        proposta.ProposalTypeId == 2 && index == 0
                          ? "Endosso"
                          : status
                      }}</b>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <vs-row class="vx-row px-4">
            <div class="vx-col w-1/2 pl-20">
              <vs-col
                vs-type="flex"
                vs-justify="end"
                vs-align="end"
                vs-w="12"
                v-if="proposta.ProposalTypeId == 2"
              >
                <visualizar-alteracoes
                  :changed="diffEndosso.length > 0"
                  :showDesfazer="false"
                  :objectDiff="diffEndosso"
                  :responsavel="responsavelDataUpdateEndosso"
                />
              </vs-col>

              <div class="block-info pr-20">
                <div class="info">
                  <h4 class="text-primary">Tomador</h4>
                  <p class="font-semibold">
                    {{
                      proposta.PolicyHolderBranch
                        ? proposta.PolicyHolderBranch.Name || {}
                        : proposta.PolicyHolder.Name || {}
                    }}
                  </p>
                  <span>
                    {{
                      proposta.PolicyHolderBranch
                        ? (proposta.PolicyHolderBranch || {}).CpfCnpj
                        : (proposta.PolicyHolder || {}).CpfCnpj | maskCpfCnpj
                    }}
                  </span>
                </div>
                <div class="info">
                  <h4 class="text-primary">Segurado</h4>
                  <p class="font-semibold">
                    {{ (seguradoDaProposta || {}).Name }}
                  </p>
                  <span>{{
                    (seguradoDaProposta || {}).CpfCnpj | maskCpfCnpj
                  }}</span>
                </div>
                <div class="info">
                  <h4 class="text-primary">Grupo/Modalidade</h4>
                  <p class="font-semibold">
                    {{ ((proposta || {}).ModalityGroup || {}).Name }}
                  </p>
                  <span>{{ ((proposta || {}).Modality || {}).Name }}</span>
                </div>
                <div class="info">
                  <h4 class="text-primary">Importância Segurada</h4>
                  <p class="font-semibold">
                    {{ (proposta || {}).InsuredAmountValue | maskCurrency }}
                  </p>
                </div>
                <div class="info">
                  <h4 class="text-primary">Prêmio</h4>
                  <p class="font-semibold">
                    {{ (proposta || {}).InsurancePremium | maskCurrency }}
                  </p>
                </div>

                <template v-if="(proposta || {}).ProposalTypeId == 2">
                  <h2 class="text-primary mb-5">Dados da Apólice</h2>

                  <div class="info">
                    <h4 class="text-primary">Número</h4>
                    <p class="font-semibold">
                      {{ ((proposta || {}).Policy || {}).Number }}
                    </p>
                  </div>

                  <div class="info">
                    <h4 class="text-primary">Data de Emissão</h4>
                    <p class="font-semibold">
                      {{
                        $utils.dateToDDMMYYYY(
                          ((proposta || {}).Policy || {}).CreateDate
                        )
                      }}
                    </p>
                  </div>

                  <div class="info">
                    <h4 class="text-primary">Vigência</h4>
                    <p class="font-semibold">
                      {{
                        `${$utils.dateToDDMMYYYY(
                          ((proposta || {}).Policy || {}).StartDate
                        )} à ${$utils.dateToDDMMYYYY(
                          ((proposta || {}).Policy || {}).EndDate
                        )}`
                      }}
                    </p>
                  </div>

                  <div class="info" v-if="(proposta || {}).Reference">
                    <router-link
                      :to="{
                        name: 'timeline-proposta',
                        params: { propostaUniqueId: proposta.Reference }
                      }"
                      target="_blank"
                      ><vs-button
                        class="font-bold p-0"
                        color="primary"
                        type="flat"
                        icon="search"
                        icon-after
                        size="large"
                      >
                        Ver proposta da apólice
                      </vs-button>
                    </router-link>
                  </div>
                </template>
              </div>
            </div>

            <div class="vx-col w-1/2 pl-20 pr-20">
              <h2 class="mb-10 text-primary font-semibold">
                Histórico de movimentação
              </h2>

              <template>
                <ul class="vx-timeline">
                  <li
                    class="mb-5"
                    v-for="item in timelineHistory || []"
                    :key="item.Id"
                  >
                    <div
                      class="timeline-icon"
                      :class="getTimelineItemColor(item)"
                    >
                      <feather-icon
                        :icon="getTimelineItemIcon(item)"
                        svgClasses="text-white stroke-current w-5 h-5"
                      />
                    </div>
                    <div class="timeline-info">
                      <span class="step-title font-semibold">
                        {{ item.Situation.Name }}
                      </span>
                      <div class="flex">
                        <!-- <span
                          style="color: #d3382e; font-weight: bold;"
                          v-if="item.StatusId == 4 || item.StatusId == 5"
                        > Deixado essa parte comentada pois não foi identificado até o momento o porque da remoção do v-if -->
                        <span style="font-weight: bold;">
                          {{ item.Observation }}
                        </span>
                        <span :v-html="getTextTimelineHistory(item)"></span>

                        <vs-button
                          class="bg-warning button-documents"
                          @click="popupDetalhamento = true"
                          v-if="exibeBtnDetalhamento(item)"
                          type="filled"
                        >
                          <div class="flex">
                            <i class="onpoint-eye mr-2 my-auto"></i>
                            <span class="text">
                              Visualizar Follow Up/Documentos
                            </span>
                          </div>
                        </vs-button>
                      </div>
                    </div>
                    <div>
                      <small class="text-grey">{{
                        formatActivityDate(
                          item.CreateByPerson.Name,
                          item.CreateDate
                        )
                      }}</small>
                    </div>
                  </li>
                </ul>
              </template>

              <ul class="flex items-center mt-10">
                <li class="flex items-center">
                  <span class="material-icons text-success"
                    >fiber_manual_record</span
                  >
                  <span>Aprovado</span>
                </li>
                <li class="flex items-center ml-4">
                  <span class="material-icons text-primary-orig"
                    >fiber_manual_record</span
                  >
                  <span>Seguradora</span>
                </li>
                <li class="flex items-center ml-4">
                  <span class="material-icons text-warning"
                    >fiber_manual_record</span
                  >
                  <span>Corretor</span>
                </li>
                <li class="flex items-center ml-4">
                  <span class="material-icons text-danger"
                    >fiber_manual_record</span
                  >
                  <span>Recusado</span>
                </li>
              </ul>
            </div>
          </vs-row>
        </div>
      </div>
    </vs-card>

    <listar-parcelas-popup
      :parcelasModal="parcelasModal"
      :propostaUniqueId="$route.params.propostaUniqueId"
      @fecharParcelasModal="parcelasModal = false"
      :autoLoad="true"
    />

    <required-documents
      :showModal="popupDetalhamento"
      :detalhamento="detalhamento"
      :showFollowUp="proposta.StatusId !== 6"
      :propostaUniqueId="$route.params.propostaUniqueId"
      @closeModal="closeModalDocuments"
      @dismissModal="dismissModalDocuments"
      @updateDocs="updateDocs"
    ></required-documents>
  </section>
</template>

<script>
import * as Enums from "@/utils/enums";
import axiosInstance from "@/axios";

import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import Upload from "@/components/az-upload/AzUpload.vue";
import VisualizarAlteracoes from "@/views/endosso/components/VisualizarAlteracoes.vue";
import ListarParcelasPopup from "./components/visualizacao/ListarParcelasPopup.vue";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import RequiredDocuments from "./components/RequiredDocuments.vue";

import * as Permissoes from "@/utils/permissions.js";
import mapfreProvider from "@/providers/mapfre-provider.js";
import TagCanalCorretorMapfre from "@/components/tag-canal-corretor-mapfre/index.vue";

export default {
  components: {
    Breadcrumb,
    Upload,
    ListarParcelasPopup,
    VisualizarAlteracoes,
    RequiredDocuments,
    TagCanalCorretorMapfre
  },
  data() {
    return {
      parcelasModal: false,
      selectedDoc: undefined,
      popupDetalhamento: false,
      modalUpload: false,
      aprovarEndossoModal: false,
      termosAceiteModal: false,
      setStatus: {},
      detalhamento: {},
      uploadDocumentoController: {},
      responsavelDataUpdateEndosso: {},
      timelineHistory: [],
      observacao: "",
      diffEndosso: [],
      tipoCanalCorretora: {},
      permissoesCorretorMapfre: []
    };
  },
  computed: {
    ...mapGetters("proposta-module", [
      "proposta",
      "tomadorDaProposta",
      "seguradoDaProposta"
    ]),

    statusMovimentacaoList() {
      const movimentacoesStatus = [];
      const movimentacoes = Object.assign([], this.timelineHistory);
      movimentacoes.sort(function(a, b) {
        return a.Id < b.Id ? -1 : a.Id > b.Id ? 1 : 0;
      });

      movimentacoes.forEach(element => {
        const movimento = this.timelineHistory.filter(item => {
          if (item.SituationId == element.SituationId) return item;
        })[0];
        if (
          !movimentacoesStatus.filter(item => {
            if (item.SituationId == movimento.SituationId) return item;
          }).length
        )
          movimentacoesStatus.push(movimento);
      });

      return movimentacoesStatus;
    },

    podeCancelarProposta() {
      const timeline = (this.proposta || {}).Timeline || {};
      return (
        this.proposta.StatusId == Enums.ProposalStatus.Cotacao ||
        this.proposta.StatusId == Enums.ProposalStatus.EmAnalise ||
        this.proposta.StatusId == Enums.ProposalStatus.AguardandoCorretor ||
        (this.proposta.StatusId == Enums.ProposalStatus.AguardandoEmissao &&
          timeline.LastSituationId == Enums.ProposalTimelineSituation.Emissao &&
          timeline.LastStatusId != Enums.ProposalTimelineStatus.Seguradora)
      );
    },

    podeRealizarEmissao() {
      const timeline = (this.proposta || {}).Timeline || {};
      // if(this.proposta.ProposalTypeId == 1) {
      return (
        timeline.LastSituationId == Enums.ProposalTimelineSituation.Emissao &&
        timeline.LastStatusId == Enums.ProposalTimelineStatus.Corretor &&
        this.proposta.StatusId != Enums.ProposalStatus.AguardandoPagamento &&
        this.proposta.StatusId != Enums.ProposalStatus.Cancelada &&
        this.proposta.StatusId != Enums.ProposalStatus.Recusada
      );
    },

    aguardandoSeguradora: {
      get() {
        const timeline = (this.proposta || {}).Timeline || {};
        return timeline.LastStatusId == 2;
      }
    },

    exibeBtnDetalhamento() {
      return itemHistory =>
        this.timelineHistory[0] == itemHistory &&
        itemHistory.StatusId == Enums.ProposalTimelineStatus.Corretor &&
        itemHistory.SituationId != Enums.ProposalTimelineSituation.Emissao;
    },

    podeExibirTagCanalCorretorMapfre() {
      return this.permissoesCorretorMapfre.includes("ExibirCanal");
    }
  },
  watch: {
    "$route.params.propostaUniqueId": function(newId, oldId) {
      this.loadProposta(newId);
    }
  },
  async mounted() {
      await this.loadProposta(this.$route.params.propostaUniqueId);
      await this.visualizarDetalhamento();
      await this.pegarPermissoesMapfre();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "timeline-proposta",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("proposta-module", ["loadPropostaByUniqueId"]),
    ...mapActions("propostas-module", ["gerarMinuta", "downloadBoleto"]),
    ...mapActions("apolice-module", ["downloadApolice"]),
    ...mapActions("endosso-module", ["getCompareChanges"]),

    closeModalDocuments() {
      this.loadProposta(this.$route.params.propostaUniqueId);
      this.popupDetalhamento = false;
    },
    dismissModalDocuments() {
      this.popupDetalhamento = false;
    },
    getTimelineItemColor(item) {
      if (item.StatusId == Enums.ProposalTimelineStatus.Aprovado)
        return "bg-success";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Seguradora)
        return "bg-primary-orig";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Corretor)
        return "bg-warning";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Recusado)
        return "bg-danger";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Cancelado)
        return "bg-danger";
      else return "bg-default";
    },
    getTimelineItemIcon(item) {
      if (item.StatusId == Enums.ProposalTimelineStatus.Aprovado)
        return "CheckIcon";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Seguradora)
        return "AlertTriangleIcon";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Corretor)
        return "AlertTriangleIcon";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Recusado)
        return "XIcon";
      else if (item.StatusId == Enums.ProposalTimelineStatus.Cancelado)
        return "XIcon";
      else return "CheckIcon";
    },
    getTextTimelineHistory(item) {
      //const timeline = (this.proposta || {}).Timeline || {}
      let text = "";

      if (this.isAguardandoPagamentoTimeline(item)) {
        text = "Aguardando pagamento";
      } else if (item.Observation) {
        if (
          this.proposta.StatusId != Enums.ProposalStatus.AguardandoPagamento
        ) {
          if (item.StatusId == Enums.ProposalTimelineStatus.Corretor) {
            text += "Solicitado: ";
          } else if (item.StatusId == Enums.ProposalTimelineStatus.Seguradora) {
            text += "Enviado: ";
          }
        }
        text += `${item.Observation.replaceAll("/n", " / ")}`;
      } else {
        text += `Etapa de ${item.Situation.Name} `;
        if (item.StatusId == Enums.ProposalTimelineStatus.Aprovado) {
          text += "executada";
        } else {
          text += "iniciada";
        }
      }

      return text;
    },

    getTimelineColor(situationGroup) {
      const currentSituationGroup = this.proposta.Timeline.Situation
        .ProposalTimelineSituationGroupId;

      if (situationGroup > currentSituationGroup) return "Pendente";
      else if (situationGroup < currentSituationGroup) return "Aprovado";
      else
        return Object.keys(Enums.ProposalTimelineStatus).find(
          k =>
            Enums.ProposalTimelineStatus[k] ===
            this.proposta.Timeline.LastStatusId
        );
    },

    async loadGetCompareChanges() {
      if (
        this.proposta.ProposalTypeId == 2 &&
        (this.proposta.Endorsement || {}).EndorsementTypeId == 2
      )
          return await this.getCompareChanges({
            uniqueId: this.$route.params.propostaUniqueId
          }).then(result => {
            this.responsavelDataUpdateEndosso = {
              UserName: result.UserOwner.Name,
              ModifiedAt: result.ModifiedAt
            };

            ((result || {}).ChangeTypes || []).forEach(item => {
              item.ChangesValues.forEach(element => {
                this.diffEndosso.push({
                  current: element.Current ? element.Current : "--",
                  origin: element.Previous ? element.Previous : "--",
                  label: element.Name,
                  key: element.Name + element.Current
                });
              });
            });
          });
    },

    getTimelineIcon(situationGroup) {
      const currentSituationGroup = this.proposta.Timeline.Situation
        .ProposalTimelineSituationGroupId;
      const currentStatus = this.proposta.Timeline.LastStatusId;

      if (situationGroup > currentSituationGroup) {
        return "query_builder";
      } else if (
        situationGroup < currentSituationGroup ||
        currentStatus == Enums.ProposalTimelineStatus.Aprovado
      ) {
        return "done";
      } else if (
        currentStatus == Enums.ProposalTimelineStatus.Recusado ||
        currentStatus == Enums.ProposalTimelineStatus.Cancelado
      )
        return "clear";
      else return "warning";
    },

    isAguardandoPagamentoTimeline(itemHistory) {
      return (
        this.proposta.StatusId == Enums.ProposalStatus.AguardandoPagamento &&
        itemHistory.SituationId == Enums.ProposalTimelineSituation.Emissao &&
        itemHistory.StatusId == Enums.ProposalTimelineStatus.Aprovado
      );
    },

    setConfirmarCancelamentoModal() {
      this.aprovarEndossoModal = false;
      this.termosAceiteModal = true;
    },

    setTermosAceiteModal() {
      this.termosAceiteModal = false;
      this.loadTimelineHistory(this.$route.params.propostaUniqueId);
    },

    verificaSeBoletoEmitido() {
      return this.proposta.ProposalInstallmentArchiveBilletId != null;
    },

    verificaSeStatusEhEmitida() {
      console.log("verificaSeStatusEhEmitida:", this.proposta);
      return this.proposta.StatusId == Enums.ProposalStatus.Emitida;
    },

    podeGerarMinuta() {
      return (
        this.proposta.StatusId != Enums.ProposalStatus.Cotacao &&
        this.proposta.StatusId != Enums.ProposalStatus.Emitida &&
        this.proposta.StatusId != Enums.ProposalStatus.Recusada &&
        this.proposta.StatusId != Enums.ProposalStatus.Cancelada &&
        this.proposta.StatusId != Enums.ProposalStatus.EmAnalise &&
        this.proposta.StatusId != Enums.ProposalStatus.AguardandoCorretor &&
        (this.proposta.Endorsement || {}).EndorsementTypeId !== 1
      );
    },

    enviarDocumento(doc) {
      const files = this.uploadDocumentoController.files();
      if (!files || files.length == 0) {
        this.$onpoint.errorModal("Selecione ao menos um arquivo");
        return;
      }
      if (doc) {
        this.$onpoint.loadingModal("Estamos enviando seu documento", () => {
          return this.uploadDocumentoController
            .upload()
            .then(uploadedFiles => {
              const request = {
                ProposalUniqueId: this.$route.params.propostaUniqueId,
                RequestDocumentUniqueId: doc.UniqueId,
                Files: uploadedFiles
              };
              return axiosInstance({
                method: "post",
                url: "api/Proposal/UploadProposalRequestDocument",
                data: request
              }).then(() => {
                const foundDoc = this.detalhamento.RequestDocuments.filter(
                  requestDoc => {
                    return requestDoc.UniqueId == doc.UniqueId;
                  }
                );
                if (foundDoc != null && foundDoc.length > 0) {
                  const idx = this.detalhamento.RequestDocuments.indexOf(
                    foundDoc[0]
                  );
                  this.detalhamento.RequestDocuments[idx].StatusDescription =
                    "Aguardando análise";
                  this.detalhamento.RequestDocuments[idx].StatusName =
                    "Aguardando_Analise";
                }

                this.$onpoint.successModal("Documento enviado com sucesso");
              });
            })
            .then(() => {
              this.modalUpload = false;
            })
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        });
      }
    },

    visualizarDetalhamento() {
        return axiosInstance
          .get(
            `api/Proposal/GetProposalTimelineInfo?ProposalUniqueId=${this.$route.params.propostaUniqueId}`
          )
          .then(response => {
            this.detalhamento = response.data.Response;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
    },

    updateDocs(docs) {
      this.detalhamento.RequestDocuments = docs;
    },

    async loadProposta(propostaUniqueId) {
      await  this.$onpoint.loadingModal("Carregando informações da proposta", async () => {
        return await this.loadPropostaByUniqueId(propostaUniqueId)
          .then(() => {
            this.loadTimelineHistory(
              this.$route.params.propostaUniqueId
            ).then(() => this.loadGetCompareChanges());
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },

    cancelarProposta(propostaUniqueId) {
      this.$onpoint.loadingModal("Estamos cancelando a proposta", () => {
        return axiosInstance
          .post(
            `api/Proposal/CancelProposal?ProposalUniqueId=${propostaUniqueId}`
          )
          .then(() => {
            this.$router.push({ name: "propostas" });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    loadTimelineHistory(propostaUniqueId) {
      return axiosInstance
        .get(
          `api/Proposal/GetProposalTimelineHistory?ProposalUniqueId=${propostaUniqueId}`
        )
        .then(response => {
          this.timelineHistory = response.data.Response;
        })
        .catch(error => {
          this.$onpoint.errorModal(error.response.Message);
        });
    },

    formatActivityDate(createdBy, creationDate) {
      const date = new Date(creationDate);

      return `Executado por ${createdBy} em ${moment(date).format(
        "DD/MM/YYYY"
      )} às ${moment(date).format("HH:mm")}`;
    },

    seguirComEmissao() {
      this.$router.push({
        name: "editar-proposta",
        params: { propostaUniqueId: this.$route.params.propostaUniqueId }
      });
    },

    async gerarMinutaClick(propostaUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.gerarMinuta(propostaUniqueId)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async gerarBoletoClick(propostaUniqueId) {
      await this.$onpoint.loading(async() => {
        return await this.downloadBoleto(propostaUniqueId)
          .then(linksToDownload => {
            //deve-se desabilitar o bloqueio de popups no navegador, senão apenas o primeiro item será baixado
            linksToDownload.forEach(element => {
              window.open(element);
            });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    downloadApoliceClick(propostaUniqueId) {
      let payload = {
        propostaUniqueId: propostaUniqueId,
        endorsement: true
      };

      this.$onpoint.loading(() => {
        return this.downloadApolice(payload)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async pegarPermissoesMapfre() {
      this.permissoesCorretorMapfre = []
      this.tipoCanalCorretora = {};
      try {
        this.permissoesCorretorMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.PROPOSTA)
        this.tipoCanalCorretora = await mapfreProvider.buscarTipoCanalPorCorretora();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async seguirComEndosso(){
      
      await this.$onpoint.loading(async () => {
        try {
           await axiosInstance
        .post(
          `/api/Endorsement/CanEndorsementEmission?proposalId=${this.proposta.Id}`
        ).then(data => {
          !!data.data.Response.PodeEmitir 
            ? 
              this.$router.push({
                name: 'endosso-emissao',
                params: {
                  uniqueId: this.proposta.UniqueId
                }
              })
            : this.$onpoint.errorModal(data.data.Response.Mensagem);
              
        })
        } catch (error) {
          this.$onpoint.errorModal();
        }
       
      })

    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";

.dados-proposta {
  p {
    font-size: 1.15rem;
  }
}

.container-timeline {
  border: 1px solid #cac2c2;

  .block-info {
    border-left: 5px solid #ff9100;
    padding-left: 20px;

    .info {
      margin-bottom: 30px;
      max-width: 60%;
    }

    .info h4 {
      font-size: 1.15rem;
      font-weight: bold;
    }

    .info p {
      font-size: 1.05rem;
    }

    .info span {
      font-size: 1rem;
    }
  }

  .macro-timeline {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #cac2c2;
    padding-bottom: 20px;
    margin-bottom: 30px;

    .status {
      display: flex;
      align-items: center;

      li {
        min-width: 200px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      li p {
        padding-top: 5px;
        font-size: 16px;
      }

      span {
        border: 2px solid #d1d1d1;
        border-radius: 50px;
        color: white;
        display: flex;
        align-items: center;
        padding: 10px;
        width: 47px;
        height: 47px;
        background: white;
        z-index: 9;
      }

      .Aprovado {
        background: #00c77a;
        border: 2px solid #00c77a;
      }

      .Seguradora {
        background: #0f6eb4;
        border: 2px solid #0f6eb4;
      }

      .Corretor {
        background: #ff9100;
        border: 2px solid #ff9100;
      }

      .Recusado,
      .Cancelado {
        background: #de7069;
        border: 2px solid #de7069;
      }

      .Pendente {
        background: #888;
        border: 2px solid #888;
      }
    }
  }

  .vx-timeline {
    .timeline-info {
      .step-title {
        font-size: 1.15rem;
      }

      .vs-button.small {
        font-size: 0.8em;

        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }
}

.popup-detalhamento {
  z-index: 52850 !important;

  .vs-list--item {
    &.Conforme {
      .vs-list--subtitle {
        background: #00c77a;
      }
    }

    &.Pendente {
      .vs-list--subtitle {
        background: #ff9100;
      }
    }

    .info p {
      font-size: 1.05rem;
    }

    &.Reprovado {
      .vs-list--subtitle {
        background: #c40f06;
      }
    }
  }

  .macro-timeline {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #cac2c2;
    padding-bottom: 20px;
    margin-bottom: 30px;

    .vs-list--subtitle {
      color: white;
      padding: 2px 5px;
      border-radius: 10px;
    }
  }

  .obs {
    width: 80%;
  }

  .badge {
    position: absolute;
    color: #0f6eb4;
    display: grid;
    place-items: center;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-left: 250px;
    margin-top: 5px;

    span {
      font-size: 16px;
    }
  }

  .mensagem {
    border: solid 1px rgba(0, 157, 223, 0.2);
  }
}

.popup-upload-documento {
  z-index: 52851 !important;
}
</style>
